<template>
    <div class="slider">
        <div class="slider__container">
            this is a slider
        </div>
    </div>
</template>
<script>
export default {

}
</script>
